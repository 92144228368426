import { ReactComponent as HouseBuilding } from "@icons/house-building-thin.svg";
import { ReactComponent as RectangleList } from "@icons/rectangle-list-light.svg";
import { ReactComponent as ScreenUsers } from "@icons/screen-users-thin.svg";
import { ReactComponent as PeopleArrows } from "@icons/people-arrows-left-right-thin.svg";
import { ReactComponent as HouseUser } from "@icons/house-user-light.svg";
import { ReactComponent as Gear } from "@icons/gear-light.svg";
import { ReactComponent as AddressCard } from "@icons/address-card-thin.svg";
import { ReactComponent as AddressBook } from "@icons/address-book-light.svg";
import { ReactComponent as Building } from "@icons/building.svg";
import { ReactComponent as Developer } from "@icons/building-user-thin.svg";
import { ReactComponent as Timeline } from "@icons/timeline.svg";
import { ReactComponent as Checklist } from "@icons/checklist.svg";
import { ReactComponent as SlidersUp } from "@icons/sliders-up-thin.svg";
import { ReactComponent as SquareDollar } from "@icons/square-dollar-light.svg";
import { ReactComponent as FilterCircleDollar } from "@icons/filter-circle-dollar-light.svg";
import { ReactComponent as Percent } from "@icons/percent-light.svg";
import { ReactComponent as Calendar } from "@icons/calendar-clock-light.svg";
import { ReactComponent as House } from "@icons/house-light.svg";
import { ReactComponent as FolderInfoIcon } from "@icons/folder-information.svg";
import { ReactComponent as PenToSquare } from "@icons/pen-to-square-light.svg";
import { ReactComponent as Newspaper } from "@icons/newspaper-light.svg";
import { ReactComponent as List } from "@icons/list-light.svg";
import { ReactComponent as IdCard } from "@icons/id-card.svg";
import { AppPermissions } from "@model/app";
import { ReactComponent as DeveloperIcon } from "@icons/user-helmet-safety-thin.svg";
import { ReactComponent as DubaiInstruction } from "@icons/dubai-instruction.svg";
import { ReactComponent as SignalIcon } from "@icons/signal.svg";
import { PipelineType } from "@model/pipeline";

export const dealRoutesConfigs = [
  {
    key: PipelineType.LEADS,
    name: "Leads",
    icon: FilterCircleDollar,
    path: "/leads",
    parent: "deal",
    permission: AppPermissions.organization,
    disable: false
  },
  {
    key: PipelineType.BUYER,
    name: "Deals",
    icon: SquareDollar,
    path: "/deals",
    parent: "deal",
    permission: AppPermissions.dealManagement,
    disable: false
  },
  {
    key: PipelineType.SELLER,
    name: "Sale & Lettings",
    icon: House,
    path: "/seller-deals",
    parent: "deal",
    permission: AppPermissions.dealManagement,
    disable: false
  },
  {
    key: PipelineType.VIEWINGS,
    name: "Viewings",
    icon: Calendar,
    path: "/viewing-deals",
    parent: "deal",
    permission: AppPermissions.organization,
    disable: false
  },
  {
    key: PipelineType.COMMISSION,
    name: "Commissions",
    icon: Percent,
    path: "/commission-deals",
    parent: "deal",
    permission: AppPermissions.organization,
    disable: false
  },
  {
    key: PipelineType.REFERRED_LEADS,
    name: "Referred Leads",
    icon: FilterCircleDollar,
    path: "/referred-leads",
    parent: "deal",
    permission: AppPermissions.organization,
    disable: false
  },
  {
    key: PipelineType.DEVELOPER,
    name: "Developers",
    icon: DeveloperIcon,
    path: "/developer-deals",
    parent: "deal",
    permission: AppPermissions.organization,
    disable: false
  },
  {
    key: PipelineType.DUBAI_INSTRUCTION,
    name: "Dubai Instructions",
    icon: DubaiInstruction,
    path: "/dubai-instructions",
    parent: "deal",
    permission: AppPermissions.organization,
    disable: false
  }
];
export type submenuType =
  | {
      name: string;
      parent: string;
      path: string;
      icon: any;
      permission: string[];
      disable: boolean;
    }[]
  | null;

type navType = {
  id: string;
  name: string;
  subMenus: submenuType | null;
  path: string;
  icon: any;
  permission: string[];
  parentOf: string[];
};

export const sidebarNavigation: navType[] = [
  // TODO: revert this change after the dashboard is ready
  // {
  //   id: "dashboard",
  //   name: "Dashboard",
  //   subMenus: null,
  //   parentOf: ["/dashboard"],
  //   path: "/dashboard",
  //   icon: SignalIcon,
  //   permission: AppPermissions.dashboardManagement
  // },
  {
    id: "contact",
    name: "Contacts",
    subMenus: [
      {
        name: "Contacts",
        icon: AddressBook,
        path: "/contacts",
        parent: "contact",
        permission: AppPermissions.contactManagement,
        disable: false
      },
      {
        name: "Subscribers",
        icon: IdCard,
        path: "/subscribers",
        parent: "contact",
        permission: AppPermissions.admin,
        disable: false
      }
    ],
    parentOf: ["/contacts", "/subscribers"],
    path: "/contacts",
    icon: AddressBook,
    permission: AppPermissions.contactManagement
  },
  {
    id: "property",
    name: "Properties",
    subMenus: [
      {
        name: "My Listings",
        icon: RectangleList,
        path: "/properties",
        parent: "property",
        permission: AppPermissions.owner,
        disable: false
      },
      {
        name: "All Listings",
        icon: RectangleList,
        path: "/properties",
        parent: "property",
        permission: AppPermissions.administrator,
        disable: false
      },
      {
        name: "Team Listings",
        icon: ScreenUsers,
        path: "/team-listings",
        parent: "property",
        permission: AppPermissions.agent,
        disable: false
      },
      {
        name: "Co-broke Listings",
        icon: PeopleArrows,
        path: "/co-broke-listings",
        parent: "property",
        permission: AppPermissions.organization,
        disable: false
      },
      {
        name: "Sellers & Landlords",
        icon: HouseUser,
        path: "/sellers-landlords",
        parent: "property",
        permission: AppPermissions.agent,
        disable: true
      },
      {
        name: "Developers",
        icon: Developer,
        path: "/developers",
        parent: "property",
        permission: AppPermissions.developerManagement,
        disable: false
      },
      {
        name: "Projects",
        icon: Building,
        path: "/projects",
        parent: "property",
        permission: AppPermissions.projectManagement,
        disable: false
      }
    ],
    parentOf: [
      "/properties",
      "/team-listings",
      "/co-broke-listings",
      "/sellers-landlords",
      "/developers",
      "/projects"
    ],
    path: "/properties",
    icon: HouseBuilding,
    permission: AppPermissions.propertyManagement
  },
  {
    id: "deal",
    name: "CRM",
    subMenus: dealRoutesConfigs,
    parentOf: [
      "/seller-deals",
      "/deals",
      "/commission-deals",
      "/viewing-deals",
      "/viewing-deals/:id",
      "/leads",
      "/developer-deals",
      "/dubai-instructions",
      "/referred-leads"
    ],
    path: "/deals",
    icon: Timeline,
    permission: AppPermissions.dealManagement
  },
  {
    id: "tasks",
    name: "Tasks",
    subMenus: null,
    parentOf: ["/tasks"],
    path: "/tasks",
    icon: Checklist,
    permission: AppPermissions.taskManagement
  },
  {
    id: "blogs",
    name: "Articles",
    subMenus: [
      {
        name: "Articles",
        icon: Newspaper,
        path: "/blog/articles",
        parent: "blogs",
        permission: AppPermissions.blogManagement,
        disable: false
      },
      {
        name: "Blog Categories",
        icon: List,
        path: "/blog/categories",
        parent: "blogs",
        permission: AppPermissions.blogManagement,
        disable: false
      }
    ],
    parentOf: ["/blog/articles", "/blog/categories"],
    path: "/blog/articles",
    icon: Newspaper,
    permission: AppPermissions.blogManagement
  },
  {
    id: "agent-centre",
    name: "Agent Centre",
    subMenus: null,
    parentOf: ["/agent-centre"],
    path: "/agent-centre",
    icon: FolderInfoIcon,
    permission: AppPermissions.storageManagement
  },
  {
    id: "setting",
    name: "Settings",
    subMenus: [
      {
        name: "My Profile",
        icon: AddressCard,
        path: "/profile",
        parent: "setting",
        permission: AppPermissions.profileSetting,
        disable: false
      },
      {
        name: "Preferences",
        icon: SlidersUp,
        path: "/preferences",
        parent: "setting",
        permission: AppPermissions.preferenceSetting,
        disable: false
      },
      {
        name: "Email Templates",
        icon: PenToSquare,
        path: "/email-templates",
        parent: "setting",
        permission: AppPermissions.emailTemplateManagement,
        disable: false
      },
      {
        name: "Blog Categories",
        icon: List,
        path: "/blog/categories",
        parent: "setting",
        permission: AppPermissions.blogManagement,
        disable: false
      }
    ],
    parentOf: ["/profile", "/logout", "/email-templates", "/preferences"],
    path: "/profile",
    icon: Gear,
    permission: AppPermissions.profileSetting
  }
];

export const userNavigation = [
  { key: "profile", name: "My Profile", href: "/profile" },
  { key: "sign_out", name: "Sign out", href: "#" }
];
